<template>
    <div v-show="!isHidden" :style="positionStyle + 'height:' + height + ';width:' + width + ';' + CSS">
      <el-button v-if="showFullscreenButton" size="mini" icon="el-icon-full-screen" class="fullscreen-button" @click="isFullscreen = !isFullscreen"></el-button>
      <el-button size="mini" icon="el-icon-delete" class="fullscreen-button" @click="clearCurrentBimModel"></el-button>
      <iframe
          :class="{'fullscreen-mode': isFullscreen}"
          :src="sourceUrl"
          width="100%"
          height="99%"
          style="border: none"
          :name="editorAlias"
        ></iframe>
        <slot></slot>
    </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'

export default {
  name: 'a-bimmodel-viewer',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [VisibleMixin, mixin],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    height: {
      type: String,
      description: 'height'
    },
    width: {
      type: String,
      description: 'width'
    },
    BimModelViewer: {
      type: Object,
      editor: 'BimModelViewer',
      default: function () {
        return {
          source: 'string',
          url: `${this.$config.api}/bimsurfer/`,
          fieldId: null
        }
      }
    },
    showFullscreenButton: {
      type: Boolean,
      description: 'btn_full_screen'
    }
  },
  data () {
    return {
      isFullscreen: false,
      sourceUrl: '',
      parentSizes: {
        width: null,
        height: null,
        transform: null
      }
    }
  },
  computed: {
    positionStyle () {
      if (!this.isFullscreen) {
        return 'position:relative;'
      }
      return null
    }
  },
  watch: {
    isFullscreen: {
      immediate: false,
      handler (value) {
        const parent = this.$el.parentElement.parentElement.parentElement
        if (value) {
          this.parentSizes.width = parent.style.width
          this.parentSizes.height = parent.style.height
          this.parentSizes.transform = parent.style.transform
          parent.style.position = 'fixed'
          parent.style.width = '100vw'
          parent.style.height = '100vh'
          parent.style.zIndex = '99999'
          parent.style.left = '0px'
          parent.style.top = '0px'
          parent.style.transform = ''
        } else {
          parent.style.width = this.parentSizes.width
          parent.style.height = this.parentSizes.height
          parent.style.position = 'absolute'
          parent.style.zIndex = ''
          parent.style.left = ''
          parent.style.top = ''
          parent.style.transform = this.parentSizes.transform
        }
      }
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  beforeMount () {
    let me = this
    window.onmessage = function (event) {
      let data = {}
      try {
        data = JSON.parse(event.data)
      } catch (e) {
      }
      if (data.isCustomMessage) {
        let card = me.getCard()
        let formData = new FormData()
        formData.append('id', card.recordId)
        formData.append('object_id', card.registryId)
        formData.append(`attr_${me.BimModelViewer.fieldId}_`, `"p/${data.id}"`)
        me.$http.put(
          `${me.$config.api}/registryservice/registry/${card.registryId}/records/${card.recordId}`,
          formData,
          {
            hideNotification: true,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
      }
    }

    if (this.BimModelViewer.fieldId) {
      let card = this.getCard()
      let attribute = `attr_${this.BimModelViewer.fieldId}_`
      let value = card.recordData[attribute]
      try {
        value = JSON.parse(value)
      } catch (e) {

      }
      // ссылка
      if (Array.isArray(value)) {
        this.sourceUrl = value[0].name || ''
        return
      }

      if (value !== null) {
        this.sourceUrl = this.BimModelViewer.url + value
        return
      }
    }

    this.sourceUrl = this.BimModelViewer.url || this.url
  },
  methods: {
    clearCurrentBimModel () {
      let card = this.getCard()
      let formData = new FormData()
      formData.append('id', card.recordId)
      formData.append('object_id', card.registryId)
      formData.append(`attr_${this.BimModelViewer.fieldId}_`, `""`)
      this.$http.put(
        `${this.$config.api}/registryservice/registry/${card.registryId}/records/${card.recordId}`,
        formData,
        {
          hideNotification: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      this.sourceUrl = this.BimModelViewer.url
    }
  }
}
</script>

<style scoped>
.fullscreen-button {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1001;
}
    .fullscreen-mode {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
    }
</style>
